import React from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import { mapStyles } from "../snazzy-map-style"

const LAT = 22.3116975
const LNG = 114.2193073

export default withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: LAT, lng: LNG }}
      defaultOptions={{ styles: mapStyles }}
    >
      <Marker position={{ lat: LAT, lng: LNG }} />
    </GoogleMap>
  ))
)
