import React, { useState } from "react"
import emailjs from "emailjs-com"
import MapWithAMarker from "./GoogleMap"
import SVGIcon from "../Global/SVGIcon"

export default function ContactInfo() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [emailStatus, setEmailStatus] = useState("Submit")

  const onSubmitForm = (e) => {
    e.preventDefault()
    const formData = {
      name,
      email,
      message,
    }

    emailjs
      .send(
        process.env.GATSBY_EMAIL_SERVICE_ID,
        process.env.GATSBY_EMAIL_TEMPLATE_ID,
        formData,
        process.env.GATSBY_EMAIL_USER_ID
      )
      .then(
        (res) => {
          console.log(res.text)
          if (res.status === 200) {
            setEmailStatus("Sent")
            // clear form
            setName("")
            setEmail("")
            setMessage("")
          }
        },
        (err) => {
          console.log(err.text)
          setEmailStatus("Email failed")
        }
      )

    setTimeout(() => {
      setEmailStatus("Submit")
    }, 9000)
  }

  const handleChange = (state, e) => {
    if (state === "name") {
      setName(e.target.value)
    }
    if (state === "email") {
      setEmail(e.target.value)
    }
    if (state === "message") {
      setMessage(e.target.value)
    }
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="contact-info">
              <h4>Our Address</h4>
              <div className="separator" />
              <p>
                1/F Wang Kwong Industrial Building
                <br />
                45 Hung To Rd
                <br />
                Kwun Tong
              </p>
            </div>

            <div className="contact-info">
              <h4>Contact Us</h4>
              <div className="separator" />
              <p>Email: hello@gianthouse.io</p>
              <p>Phone: +852 98305435</p>
              <div className="my-4">
                <a
                  href="https://wa.me/85298305435"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn default-btn text-uppercase btn-round">
                    <SVGIcon
                      name="whatsapp"
                      width={20}
                      className="contact-icon"
                    />
                    Message Us
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col py-4">
            <form className="contact-info" onSubmit={onSubmitForm}>
              <div className="form-group">
                <label htmlFor="emailAddress">Email</label>
                <input
                  value={email}
                  onChange={(e) => handleChange("email", e)}
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  aria-describedby="emailHelp"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  value={name}
                  onChange={(e) => handleChange("name", e)}
                  type="text"
                  className="form-control"
                  id="name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="queryMessage">Message</label>
                <textarea
                  value={message}
                  onChange={(e) => handleChange("message", e)}
                  className="form-control"
                  id="queryMessage"
                  rows="5"
                  required
                ></textarea>
              </div>

              {emailStatus === "Sent" && (
                <div className="alert alert-info reply-text" role="alert">
                  Thank you for your enquiry. We will be in touch shortly.
                </div>
              )}
              <button
                className="btn default-btn text-uppercase btn-round"
                type="Send"
              >
                {emailStatus !== "Submit" && (
                  <SVGIcon
                    name={emailStatus === "Sent" ? "check" : "X"}
                    width={20}
                    className="contact-icon"
                  />
                )}
                {emailStatus}
              </button>
            </form>
          </div>
        </div>
        <MapWithAMarker
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          loadingElement={<div style={{ height: `100%` }} />}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAP_API_KEY}`}
        />
      </div>
    </section>
  )
}
