import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"
import ContactInfo from "../components/Contact/ContactInfo"

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" keywords={[`contact`, `message`]} />
    <BannerSection img={data.img.childImageSharp.fluid} title="Get In Touch." />
    <ContactInfo />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "contact-banner.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 100
          duotone: { highlight: "#0c3844", shadow: "#0c3844", opacity: 50 }
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ContactPage
